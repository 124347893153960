// src/pages/LegalNotice.js
import React from 'react';

const LegalNotice = () => {
    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Legal Notice</h1>
            <p>[Your Company Name]</p>
            {/* Add the rest of your Legal Notice content here */}
        </div>
    );
};

export default LegalNotice;
