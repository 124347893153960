// src/pages/PrivacyPolicy.js
import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>
            <p>Effective Date: [Date]</p>
            <p>Welcome to [Your Company Name]...</p>
            {/* Add the rest of your Privacy Policy content here */}
        </div>
    );
};

export default PrivacyPolicy;
