import { useState } from "react";
import logo from "../assets/images/logo/logo.svg"
function SignUp() {
  //const API_URL = process.env.REACT_APP_API_URL;
  const ADMIN_URL = process.env.REACT_APP_ADMIN_URL;
  const [selectedPhoto, setSelectedPhoto] = useState(null);

  const handlePhotoChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setSelectedPhoto(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };
    return (
      <section class="bg-[#F4F7FF] py-14 lg:py-20">
      <div class="container">
        <div class="flex flex-wrap -mx-4">
          <div class="w-full px-4">
            <div
              class="
                max-w-[525px]
                mx-auto
                text-center
                bg-white
                rounded-lg
                relative
                overflow-hidden
                py-14
                px-8
                sm:px-12
                md:px-[60px]
                wow
                fadeInUp
              "
              data-wow-delay=".15s"
            >
              <div class="mb-10  text-center">
                <a
                  href="/"
                  class="inline-block max-w-[160px] mx-auto"
                >
                  <img src={logo} alt="logo" />
                </a>
              </div>
        <div className="mb-10  text-center">
        <label
          htmlFor="photoInput"
          className="w-full h-64 border border-dashed border-gray-300 flex justify-center items-center cursor-pointer"
        >
          {selectedPhoto ? (
            <img
              src={selectedPhoto}
              alt="Selected"
              className="object-cover"
            />
          ) : (
            <span className="text-gray-500">company  logo</span>
          )}
        </label>
        <input
          type="file"
          id="photoInput"
          className="hidden"
          onChange={handlePhotoChange}
        />
      </div>
              <form>
                <div class="mb-6">
                  <input
                    type="text"
                    placeholder="Company Name"
                    class="
                      w-full
                      rounded-md
                      border
                      bordder-[#E9EDF4]
                      py-3
                      px-5
                      bg-[#FCFDFE]
                      text-base text-body-color
                      placeholder-[#ACB6BE]
                      outline-none
                      focus-visible:shadow-none
                      focus:border-primary
                      transition
                    "
                  />
                </div>
                <div class="mb-6">
                  <input
                    type="phon"
                    placeholder="Phone"
                    class="
                      w-full
                      rounded-md
                      border
                      bordder-[#E9EDF4]
                      py-3
                      px-5
                      bg-[#FCFDFE]
                      text-base text-body-color
                      placeholder-[#ACB6BE]
                      outline-none
                      focus-visible:shadow-none
                      focus:border-primary
                      transition
                    "
                  />
                </div>
                <div class="mb-6">
                  <input
                    type="email"
                    placeholder="Email"
                    class="
                      w-full
                      rounded-md
                      border
                      bordder-[#E9EDF4]
                      py-3
                      px-5
                      bg-[#FCFDFE]
                      text-base text-body-color
                      placeholder-[#ACB6BE]
                      outline-none
                      focus-visible:shadow-none
                      focus:border-primary
                      transition
                    "
                  />
                </div>
                <div class="mb-6">
                  <input
                    type="password"
                    placeholder="Password"
                    class="
                      w-full
                      rounded-md
                      border
                      bordder-[#E9EDF4]
                      py-3
                      px-5
                      bg-[#FCFDFE]
                      text-base text-body-color
                      placeholder-[#ACB6BE]
                      outline-none
                      focus-visible:shadow-none
                      focus:border-primary
                      transition
                    "
                  />
                </div>
                <div class="mb-10">
                  <input
                    type="submit"
                    value="Sign Up"
                    class="
                      w-full
                      rounded-md
                      border
                      bordder-primary
                      py-3
                      px-5
                      bg-primary
                      text-base text-white
                      cursor-pointer
                      hover:shadow-md
                      transition
                      duration-300
                      ease-in-out
                    "
                  />
                </div>
              </form>
             

              <p class="text-base text-[#adadad] mb-4">
                By creating an account you are agree with our
                <a
                  href="/privacy-policy"
                  class="text-primary hover:underline"
                >
                  Privacy Policy
                </a>
              </p>

              <p class="text-base text-[#adadad]">
                Already have an account?
                <a href={ADMIN_URL+"/admin"} class="text-primary hover:underline">
                  Sign In
                </a>
              </p>

              <div>
                <span class="absolute top-1 right-1">
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="1.39737"
                      cy="38.6026"
                      r="1.39737"
                      transform="rotate(-90 1.39737 38.6026)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.39737"
                      cy="1.99122"
                      r="1.39737"
                      transform="rotate(-90 1.39737 1.99122)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.6943"
                      cy="38.6026"
                      r="1.39737"
                      transform="rotate(-90 13.6943 38.6026)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.6943"
                      cy="1.99122"
                      r="1.39737"
                      transform="rotate(-90 13.6943 1.99122)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="25.9911"
                      cy="38.6026"
                      r="1.39737"
                      transform="rotate(-90 25.9911 38.6026)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="25.9911"
                      cy="1.99122"
                      r="1.39737"
                      transform="rotate(-90 25.9911 1.99122)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.288"
                      cy="38.6026"
                      r="1.39737"
                      transform="rotate(-90 38.288 38.6026)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.288"
                      cy="1.99122"
                      r="1.39737"
                      transform="rotate(-90 38.288 1.99122)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.39737"
                      cy="26.3057"
                      r="1.39737"
                      transform="rotate(-90 1.39737 26.3057)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.6943"
                      cy="26.3057"
                      r="1.39737"
                      transform="rotate(-90 13.6943 26.3057)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="25.9911"
                      cy="26.3057"
                      r="1.39737"
                      transform="rotate(-90 25.9911 26.3057)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.288"
                      cy="26.3057"
                      r="1.39737"
                      transform="rotate(-90 38.288 26.3057)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.39737"
                      cy="14.0086"
                      r="1.39737"
                      transform="rotate(-90 1.39737 14.0086)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.6943"
                      cy="14.0086"
                      r="1.39737"
                      transform="rotate(-90 13.6943 14.0086)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="25.9911"
                      cy="14.0086"
                      r="1.39737"
                      transform="rotate(-90 25.9911 14.0086)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.288"
                      cy="14.0086"
                      r="1.39737"
                      transform="rotate(-90 38.288 14.0086)"
                      fill="#3056D3"
                    />
                  </svg>
                </span>
                <span class="absolute left-1 bottom-1">
                  <svg
                    width="29"
                    height="40"
                    viewBox="0 0 29 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="2.288"
                      cy="25.9912"
                      r="1.39737"
                      transform="rotate(-90 2.288 25.9912)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="14.5849"
                      cy="25.9911"
                      r="1.39737"
                      transform="rotate(-90 14.5849 25.9911)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.7216"
                      cy="25.9911"
                      r="1.39737"
                      transform="rotate(-90 26.7216 25.9911)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="2.288"
                      cy="13.6944"
                      r="1.39737"
                      transform="rotate(-90 2.288 13.6944)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="14.5849"
                      cy="13.6943"
                      r="1.39737"
                      transform="rotate(-90 14.5849 13.6943)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.7216"
                      cy="13.6943"
                      r="1.39737"
                      transform="rotate(-90 26.7216 13.6943)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="2.288"
                      cy="38.0087"
                      r="1.39737"
                      transform="rotate(-90 2.288 38.0087)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="2.288"
                      cy="1.39739"
                      r="1.39737"
                      transform="rotate(-90 2.288 1.39739)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="14.5849"
                      cy="38.0089"
                      r="1.39737"
                      transform="rotate(-90 14.5849 38.0089)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.7216"
                      cy="38.0089"
                      r="1.39737"
                      transform="rotate(-90 26.7216 38.0089)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="14.5849"
                      cy="1.39761"
                      r="1.39737"
                      transform="rotate(-90 14.5849 1.39761)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.7216"
                      cy="1.39761"
                      r="1.39737"
                      transform="rotate(-90 26.7216 1.39761)"
                      fill="#3056D3"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    );
  }
  
  export default SignUp;