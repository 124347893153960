import About from '../components/About';
import Features from '../components/Features';
import Team from '../components/Team'
import Faq from '../components/Faq';
import HomeLayout from '../layouts/HomeLaout';
import Contact from '../components/Contact';
import Hero from '../components/Hero';
function Home() {
  return (
    <HomeLayout>
      <Hero/>
      <Features/>
      <About/>
      <Faq/>
      <Team/>
      <Contact/>
    </HomeLayout>
  );
}

export default Home;
