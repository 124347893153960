import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
const HomeLayout = ({ children }) =>  {
  return (
    <>
      <NavBar/>
      {children}
      <Footer/>
      
    </>
  );
}

export default HomeLayout;
