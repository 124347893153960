// src/pages/TermsOfService.js
import React from 'react';

const TermsOfService = () => {
    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Terms of Service</h1>
            <p>Effective Date: [Date]</p>
            <p>By using our website and desktop application...</p>
            {/* Add the rest of your Terms of Service content here */}
        </div>
    );
};

export default TermsOfService;
