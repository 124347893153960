import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import about_image from "../assets/images/about/about-image.svg"
function About() {
  const { t,  } = useTranslation();
    return (
      <section
      id="about"
      className="pt-20 lg:pt-[120px] pb-20 lg:pb-[120px] bg-[#f3f4fe]"
    >
      <div className="container">
        <div className="bg-white wow fadeInUp" data-wow-delay=".2s">
          <div className="flex flex-wrap -mx-4">
            <div className="w-full px-4">
              <div
                className="
                  lg:flex
                  items-center
                  justify-between
                  border
                  overflow-hidden
                "
              >
                <div
                  className="
                    lg:max-w-[565px]
                    xl:max-w-[640px]
                    w-full
                    py-12
                    px-7
                    sm:px-12
                    md:p-16
                    lg:py-9 lg:px-16
                    xl:p-[70px]
                  "
                >
                  <span
                    className="
                      text-sm
                      font-medium
                      text-white
                      py-2
                      px-5
                      bg-primary
                      inline-block
                      mb-5
                    "
                  >
                     {t("AboutUs")}
                  </span>
                  <h2
                    className="
                      font-bold
                      text-3xl
                      sm:text-4xl
                      2xl:text-[40px]
                      sm:leading-snug
                      text-dark
                      mb-6
                    "
                  >
                     {t("AboutUsTitle")}
                  </h2>
                  <p className="text-base text-body-color mb-9 leading-relaxed">
                    {t("AboutUsContent")}
                  </p>
                  <Link
                    to="/signup"
                    className="
                      inline-flex
                      items-center
                      justify-center
                      py-4
                      px-6
                      rounded
                      text-white
                      bg-primary
                      text-base
                      font-medium
                      hover:bg-opacity-90 hover:shadow-lg
                      transition
                      duration-300
                      ease-in-out
                    "
                  >
                     {t("StartNow")}
                  </Link>
                </div>
                <div className="text-center">
                  <div className="relative inline-block z-10">
                    <img
                      src={about_image}
                      alt="About ZedCom"
                      className="mx-auto lg:ml-auto"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    );
  }
  
  export default About;