import { Link } from "react-router-dom";

function NotFound() {
    return (
        <section class="bg-white py-14 lg:py-20">
        <div class="container">
          <div class="flex flex-wrap -mx-4">
            <div class="w-full px-4">
              <div
                class="
                  max-w-[850px]
                  mx-auto
                  text-center
                  bg-white
                  rounded-lg
                  relative
                  overflow-hidden
                  py-20
                  px-8
                  sm:px-12
                  md:px-[60px]
                  shadow-lg
                  wow
                  fadeInUp
                "
                data-wow-delay=".2s"
              >
                <h2
                  class="
                    font-bold
                    mb-8
                    text-dark text-3xl
                    sm:text-4xl
                    lg:text-[40px]
                    xl:text-[42px]
                  "
                >
                  404 - We couldn't find that page.
                </h2>
                <h3 class="font-normal mb-8 text-xl md:text-2xl text-dark-700">
                  Maybe you can find what you need here?
                </h3>
                <ul class="flex flex-wrap justify-center">
                  <li>
                    <Link
                      to="/"
                      class="
                        text-base
                        font-medium
                        py-3
                        px-6
                        text-dark
                        rounded-md
                        mx-2
                        my-1
                        inline-block
                        bg-[#f5f8ff]
                        hover:bg-primary hover:text-white
                      "
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/signin"
                      class="
                        text-base
                        font-medium
                        py-3
                        px-6
                        text-dark
                        rounded-md
                        mx-2
                        my-1
                        inline-block
                        bg-[#f5f8ff]
                        hover:bg-primary hover:text-white
                      "
                    >
                      Login
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/contact"
                      class="
                        text-base
                        font-medium
                        py-3
                        px-6
                        text-dark
                        rounded-md
                        mx-2
                        my-1
                        inline-block
                        bg-[#f5f8ff]
                        hover:bg-primary hover:text-white
                      "
                    >
                      contact
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
  
  export default NotFound;