import './assets/css/animate.css';
import './assets/css/tailwind.css';
import Home from './pages/Home';
import SignUp from './pages/SignUp';
import React from 'react';
import { BrowserRouter , Route, Routes } from 'react-router-dom';
import './i18n';
import PrivacyPolicy from './pages/PrivacyPolicy';
import LegalNotice from './pages/LegalNotice';
import TermsOfService from './pages/TermsOfService';
import HomeLayout from './layouts/HomeLaout';
import Contact from './components/Contact';
import Team from './components/Team';
import Pricing from './components/Pricing';
import About from './components/About';
import NotFound from './pages/NotFound';

const App = () => {
  return (
  <BrowserRouter>
    <div className='App'>
    
      <div>
        <Routes>
          <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
          <Route path="/legal-notice" element={<LegalNotice/>} />
          <Route path="/terms-of-service" element={<TermsOfService/>} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/contact" element={<HomeLayout><Contact /></HomeLayout>} />
          <Route path="/about" element={<HomeLayout><About /></HomeLayout>} />
          <Route path="/pricing" element={<HomeLayout><Pricing /></HomeLayout>} />
          <Route path="/Team" element={<HomeLayout><Team /></HomeLayout>} />
          <Route path="/" exact element={<Home/>} />
          <Route path="*" element={<NotFound/>}/>
        </Routes>
      </div>
    
    </div>
    </BrowserRouter>
  );
};

export default App;

