import { useState } from "react";
import { useTranslation } from "react-i18next";

const ReadMore = ({ text }) => {
    const [isReadMore, setIsReadMore] = useState(true);
    const { t,  } = useTranslation();
    const maxLength = 200;
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
      <>
      
                <p className="text-body-color mb-8 lg:mb-11">
                {isReadMore ? `${text.slice(0, maxLength)}...` : text}
                </p>
                <button
                  onClick={toggleReadMore}
                  className="font-medium text-base text-body-color hover:text-primary"
                >
                  {isReadMore ? t("ReadMore") : t("ShowLess")}
                </button>
      
      </>
    );
  };

  export default ReadMore;