import { useTranslation } from "react-i18next";

const PriceItem = ({ price,popular, items }) => {
  const { t,  } = useTranslation();
  return (
    <div className="w-full md:w-1/2 lg:w-1/3">
      <div className={!popular ? `bg-white rounded-xl relative z-10 overflow-hidden
                  border border-primary border-opacity-20 shadow-pricing py-10 px-8 
                  sm:p-12 lg:py-10 lg:px-6 xl:p-12 mb-10 text-center wow fadeInUp"
                  data-wow-delay=".15s` : `bg-primary bg-gradient-to-b
                  from-primary to-[#179BEE] rounded-xl relative z-10
                  overflow-hidden shadow-pricing py-10 px-8 sm:p-12 lg:py-10 
                  lg:px-6 xl:p-12 mb-10 text-center wow fadeInUp`}>
        {popular ? (<span className="inline-block py-2 px-6 border border-white rounded-full text-base
                                  font-semibold text-primary bg-white uppercase mb-5">
                      {t("Popular")}
                    </span>) : ''}
        <span className={`${popular ?'text-white' : 'text-dark' } font-medium text-base uppercase block mb-2`}>
          {t("StartingFrom")}
        </span>
        <h2 className={`font-semibold ${popular ?'text-white' : 'text-primary' } mb-9 text-[28px]`}>
          {t(price)}
        </h2>

        <div className="mb-10">
          {items.map((item,index ) => ( 
            <p key={index} 
                className={`text-base font-medium ${popular ?'text-white' : 'text-body-color' } leading-loose mb-1`}>
              {t(item)}
            </p>)
          )}
        </div>
        <div className="w-full">
          <a href="/"
            className={`inline-block text-base font-medium border 
                    hover:text-white 
                    rounded-full text-center py-4 px-11
                    transitionduration-300ease-in-out
                    ${popular ? 'text-dark bg-white border-white hover:bg-dark hover:border-dark' :
                      'text-primary bg-transparent border-[#D4DEFF] hover:bg-primary hover:border-primary'}`}>
            {t("PurchaseNow")}
          </a>
        </div>
      </div>
    </div>
  );};

function  Pricing(){
  const { t,  } = useTranslation();
  const offre = [{
                  price:"Price-1",
                  popular:false,
                  items:["OneUser","oneStore","FreeUpdates","OneOnSiteVisit","RemoteSupport"]
                },
                 {
                  price:"Price-2",
                  popular:true,
                  items:["FiveUser","oneStore","FreeUpdates","OneOnSiteVisit","RemoteSupport"]
                },
                 {price:"Price-3",
                 popular:false,
                 items:["UnlimitedUser","MultiStores","FreeUpdates","ThreeOnSiteVisit","RemoteSupport"]
                },]
return (
    <section id="pricing" className=" bg-white pt-20 lg:pt-[120px] pb-12
                                  lg:pb-[90px] relative z-20 overflow-hidden">
      <div className="container">
        <div className="flex flex-wrap -mx-4">
          <div className="w-full px-4">
            <div className="text-center mx-auto mb-[60px] lg:mb-20 max-w-[620px]">
              <span className="font-semibold text-lg text-primary mb-2 block">
                {t("PricingTable")}
              </span>
              <h2
                className="
                  font-bold
                  text-3xl
                  sm:text-4xl
                  md:text-[40px]
                  text-dark
                  mb-4
                "
              >
                {t("PricingTableTitle")}
              </h2>
              <p
                className="
                  text-lg
                  sm:text-xl
                  leading-relaxed
                  sm:leading-relaxed
                  text-body-color
                "
              >
                {t("PricingTableContent")}
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap items-center justify-center">
          {offre.map((item,index)=>(
            <PriceItem key={index} price={item.price} popular={item.popular} items={item.items}/>
          ))}
          
          
        </div>
      </div>
    </section>
);
}

export default Pricing;