import { Link } from "react-router-dom";
import logo_white from "../assets/images/logo/logo-white.svg"
import logo from "../assets/images/logo/logo.svg"
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
function NavBar() {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language)
    const ADMIN_URL = process.env.REACT_APP_ADMIN_URL;
    const [isNavbarOpen, setIsNavbarOpen] = useState(false);
    const [submenuStates, setSubmenuStates] = useState({});
    const [isSticky, setIsSticky] = useState(false);
    const headerRef = useRef(null);
    const logoRef = useRef(null);
    const backToTopRef = useRef(null);
    const toggleNavbar = () => {
        setIsNavbarOpen(!isNavbarOpen);
    };

    const toggleSubmenu = (index) => {
        setSubmenuStates((prevStates) => ({
            ...prevStates,
            [index]: !prevStates[index],
        }));
    };

    const closeNavbar = () => {
        setIsNavbarOpen(false);
    };
    const languageName = (lng) => {
        switch (lng) {
            case 'ar': return "Arabic";
            case 'fr': return "French";
            case 'en': return "English";
            default: break;
        };
    }
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setLanguage(lng);
        closeNavbar();
        const isRTL = lng === 'ar';
        document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr');
        document.body.classList.toggle('rtl', isRTL);
        document.body.classList.toggle('ltr', !isRTL);
    };
    useEffect(() => {
        const isRTL = i18n.language === 'ar';
        document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr');
        document.body.classList.toggle('rtl', isRTL);
        document.body.classList.toggle('ltr', !isRTL);

        const handleScroll = () => {
          if (headerRef.current) {
            const sticky = headerRef.current.offsetTop;
    
            if (window.pageYOffset > sticky) {
              setIsSticky(true);
              headerRef.current.classList.add('sticky');
            } else {
              setIsSticky(false);
              headerRef.current.classList.remove('sticky');
            }
    
            if (backToTopRef.current) {
              if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
                backToTopRef.current.style.display = 'flex';
              } else {
                backToTopRef.current.style.display = 'none';
              }
            }
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, [i18n.language]);
    
    return (<>
        <div
            ref={headerRef}
            className="
          ud-header
          bg-primary
          absolute
          top-0
          left-0
          z-40
          w-full
          flex
          items-center
        "
        >
            <div className="container" >
                <div className="flex -mx-4 items-center justify-between relative">
                    <div className="px-4 w-60 max-w-full">
                        <Link to="/" className="navbar-logo w-full block py-5">
                            <img
                                ref={logoRef}
                                src={isSticky ? logo : logo_white}
                                alt="Logo zeedcom"
                                className="w-full header-logo"
                            />
                        </Link>
                    </div>
                    <div className="flex px-4 justify-between items-center w-full">
                        <div>
                            <button
                                id="navbarToggler"
                                className={`${isNavbarOpen ? 'navbarTogglerActive' : ''}
                                        block
                                        absolute
                                        ${language==='ar' ? 'left-1' : 'right-4'}
                                        top-1/2
                                        -translate-y-1/2
                                        lg:hidden
                                        focus:ring-2
                                        ring-primary
                                        px-3
                                        py-[6px]
                                        rounded-lg`}
                                onClick={toggleNavbar}
                            >
                                <span
                                    className="relative w-[30px] h-[2px] my-[6px] block bg-white"
                                ></span>
                                <span
                                    className="relative w-[30px] h-[2px] my-[6px] block bg-white"
                                ></span>
                                <span
                                    className="relative w-[30px] h-[2px] my-[6px] block bg-white"
                                ></span>
                            </button>
                            <nav
                                id="navbarCollapse"
                                className={`
                    absolute
                    py-5
                    lg:py-0 lg:px-4
                    xl:px-6
                    bg-white
                    lg:bg-transparent
                    shadow-lg
                    rounded-lg
                    max-w-[250px]
                    w-full
                    lg:max-w-full lg:w-full
                    ${language==='ar' ? 'left-1' : 'right-4'}
                    top-full
                    ${isNavbarOpen ? '' : 'hidden'}
                    lg:block lg:static lg:shadow-none
                    `}
                            >
                                <ul className="blcok lg:flex">
                                    <li className="relative group">
                                        <Link
                                            onClick={closeNavbar}
                                            to="/#home"
                                            className="
                          ud-menu-scroll
                          text-base text-dark
                          lg:text-white
                          lg:group-hover:opacity-70
                          lg:group-hover:text-white
                          group-hover:text-primary
                          py-2
                          lg:py-6 lg:inline-flex lg:px-0
                          flex
                          mx-8
                          lg:mr-0
                        "
                                        >
                                            {t('Home')}
                                        </Link>
                                    </li>
                                    <li className="relative group">
                                        <Link
                                            to="/about#about"
                                            onClick={closeNavbar}
                                            className=" ud-menu-scroll
                                                        text-base text-dark
                                                        lg:text-white
                                                        lg:group-hover:opacity-70
                                                        lg:group-hover:text-white
                                                        group-hover:text-primary
                                                        py-2
                                                        lg:py-6 lg:inline-flex lg:px-0
                                                        flex
                                                        mx-8
                                                        lg:mr-0 lg:ml-7
                                                        xl:ml-12
                                                        ">
                                            {t("About")}
                                        </Link>
                                    </li>
                                  
                                    <li className="relative group">
                                        <Link
                                            href="/team#team"
                                            onClick={closeNavbar}
                                            className="
                          ud-menu-scroll
                          text-base text-dark
                          lg:text-white
                          lg:group-hover:opacity-70
                          lg:group-hover:text-white
                          group-hover:text-primary
                          py-2
                          lg:py-6 lg:inline-flex lg:px-0
                          flex
                          mx-8
                          lg:mr-0 lg:ml-7
                          xl:ml-12
                        "
                                        >
                                            {t("Team")}
                                        </Link>
                                    </li>
                                    <li className="relative group">
                                        <Link
                                            href="/contact#contact"
                                            className="
                          ud-menu-scroll
                          text-base text-dark
                          lg:text-white
                          lg:group-hover:opacity-70
                          lg:group-hover:text-white
                          group-hover:text-primary
                          py-2
                          lg:py-6 lg:inline-flex lg:px-0
                          flex
                          mx-8
                          lg:mr-0 lg:ml-7
                          xl:ml-12
                        "
                                        >
                                            {t("Contact")}
                                        </Link>
                                    </li>
                                    <li className="relative group submenu-item">
                                        <i
                                            onClick={(e) => {
                                                e.preventDefault();
                                                toggleSubmenu(1);
                                              }}
                                            className={`
                          cursor-pointer
                          text-base text-dark
                          
                          py-2
                          lg:py-6 lg:inline-flex lg:pl-0 lg:pr-4
                          flex
                          mx-8
                          lg:mr-0 lg:ml-8
                          xl:ml-12
                          relative
                          font-bold
                        `}
                                        >
                                            {t(languageName(language))}
                                        </i>
                                        <div
                                            className={`
                          cursor-pointer
                          submenu
                          ${submenuStates[1] ? '' : 'hidden'}
                          relative
                          lg:absolute
                          w-[250px]
                          top-full
                          lg:top-[110%]
                          left-0
                          rounded-sm
                          lg:shadow-lg
                          p-4
                          lg:block lg:opacity-0 lg:invisible
                          group-hover:opacity-100
                          lg:group-hover:visible lg:group-hover:top-full
                          bg-white
                          transition-[top]
                          duration-300`}
                                        >
                                            <i
                                                onClick={() => changeLanguage('en')}
                                                
                                                className="
                            block
                            text-sm text-body-color
                            rounded
                            hover:text-primary
                            py-[10px]
                            px-4
                          "
                                            >
                                                {t("English")}
                                            </i>
                                            <i
                                                onClick={() => changeLanguage('fr')}
                                                className="
                            block
                            text-sm text-body-color
                            rounded
                            hover:text-primary
                            py-[10px]
                            px-4
                          "
                                            >
                                                {t("French")}
                                            </i>
                                            <i
                                                onClick={() => changeLanguage('ar')}
                                                className="
                            block
                            text-sm text-body-color
                            rounded
                            hover:text-primary
                            py-[10px]
                            px-4
                          "
                                            >
                                                {t("Arabic")}
                                            </i>

                                        </div>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div className="sm:flex justify-end hidden pr-16 lg:pr-0">
                            <a
                                href={ADMIN_URL + "/admin"}
                                className="
                    text-base
                    font-medium
                    text-white
                    hover:opacity-70
                    py-3
                    px-7
                    loginBtn
                  "
                            >
                                {t("SignIn")}
                            </a>
                            <Link className="
                    text-base
                    font-medium
                    text-white
                    bg-white bg-opacity-20
                    rounded-lg
                    py-3
                    px-6
                    hover:bg-opacity-100 hover:text-dark
                    signUpBtn
                    duration-300
                    ease-in-out
                  "
                                to="signup">
                                {t("SignUp")}
                            </Link>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>
    );
}

export default NavBar;



